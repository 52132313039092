<template>
  <div>
    <section id="login-page">
      <div class="container my-5">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="card shadow p-3 bg-body rounded">
              <div class="card-header mb-3 px-4">
                <h3 class="card-title my-1 text-center">{{ $t("message.title-tajimi-admin") }}</h3>
              </div>
              <div class="card-body p-3 m-1">
                <div class="login error-message"></div>
                <form id="form-login" method="POST" @submit.prevent="login">
                  <div class="mb-2">
                    <v-label>{{ $t("message.label-email") }}</v-label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      v-model="dataLogin.email"
                    />
                  </div>
                  <div class="mb-2">
                    <v-label>
                      {{ $t("message.label-password") }}
                    </v-label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      v-model="dataLogin.password"
                    />
                  </div>
                  <div class="d-grid  mt-4">
                    <v-btn type="submit" @click.prevent="login" depressed color="primary">
                      {{$t("message.btn-login")}}
                    </v-btn>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      dataLogin: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      this.$store.dispatch("auth_module/login", this.dataLogin);
    },
  },
  created() {
    // window.addEventListener('keydown', (e) => {
    //   if(e.key == 'Enter') {
    //     this.login()
    //   }
    // })
  }
};
</script>

<style></style>
